import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StoreService } from '../services/store/store.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
	constructor(private store: StoreService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.store.user$.value.token;

		if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
					'Access-Control-Allow-Origin': '*',
					IsMobile: 'true',
					'Content-Type': 'application/json',
					Accept: 'application/json;charset=UTF-8',
				},
			});
		}

		return next.handle(request);
	}
}
