import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
	},
	{
		path: 'home',
		loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
	},
	{
		path: 'sobre',
		loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule),
	},
	{
		path: 'contato',
		loadChildren: () => import('./pages/contato/contato.module').then(m => m.ContatoPageModule),
	},
	{
		path: 'faq',
		loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqPageModule),
	},
	{
		path: 'noticias',
		loadChildren: () => import('./pages/noticias/noticias.module').then(m => m.NoticiasPageModule),
	},
	{
		path: 'coleta',
		loadChildren: () => import('./pages/coleta/coleta.module').then(m => m.ColetaPageModule),
	},
	{
		path: 'cliente-area',
		loadChildren: () => import('./pages/_modules/modules.module').then(m => m.ModulesModule),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
