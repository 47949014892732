import { Injectable } from '@angular/core';
import { IRedirect } from './redirect.interface';
import { RedirectService } from '../redirect.service';
import { StoreService } from '../../../services/store/store.service';

@Injectable({ providedIn: 'root' })
export class SessionRedirectService implements IRedirect {
	constructor(private store: StoreService, private redirectService: RedirectService) {}

	redirect = async () => {
		if (!!this.store.user$.value.token) {
			this.redirectService.goToLoggedArea();
			return Promise.resolve(true);
		}
		return Promise.resolve(false);
	};
}
