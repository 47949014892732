import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { interval, Observable, Subscriber } from 'rxjs';
import { finalize, map, share, take } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';
import { environment } from '../../environments/environment';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
	private count = 0;
	private isInload: boolean;
	private interval: any;
	private timeIntervalTake: number;
	private timeInterval: number;
	private whiteList = [`${environment.api}/calcularCotacao.php`, `${environment.api}/verificarData.php`];

	constructor(protected loadingService: LoadingService) {
		this.timeIntervalTake = 2;
		this.timeInterval = 500;
	}

	/**
	 * Cria um Observable para cancelar a animação do loading depois de um certo tempo.
	 * Caso venha múltiplas consultas REST, a animação é cancelada apenas quando o ultimo retornar
	 */
	private setIntervalToStop() {
		let timeIntervalTakeLast = 0;

		if (this.interval instanceof Subscriber) {
			this.interval.unsubscribe();
		}

		this.interval = interval(this.timeInterval)
			.pipe(
				take(this.timeIntervalTake),
				map(i => {
					timeIntervalTakeLast = i;
					return i;
				}),
				finalize(() => {
					if (this.timeIntervalTake === timeIntervalTakeLast + 1) {
						this.loadingService.stop();
					}
				})
			)
			.subscribe();
	}

	private watchToAnimation(value: boolean) {
		if (this.isInload !== value) {
			if (this.isInload === undefined || value === true) {
				this.loadingService.start();
			} else {
				this.setIntervalToStop();
			}
			this.isInload = value;
		}
	}

	/**
	 * verifica os contadores de consultas REST
	 */
	private watchCounts() {
		if (this.count > 0) {
			this.watchToAnimation(true);
		} else if (this.count === 0) {
			this.watchToAnimation(false);
		}
	}

	private actionResponse() {
		--this.count;
		this.watchCounts();
	}

	/**
	 * interceptor de consultas REST
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const observable = next.handle(req).pipe(share());
		const containsInWhiteList = this.whiteList.includes(req.url);

		if (!containsInWhiteList) {
			this.count++;
			this.watchCounts();

			observable.subscribe(
				request => {},
				error => {
					this.actionResponse();
				},
				() => {
					this.actionResponse();
				}
			);
		}

		return observable;
	}
}
