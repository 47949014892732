export class FormValidModel {
	creditCard: boolean;
	termsOfUser: boolean;
	volumeList: boolean[];

	constructor() {
		this.creditCard = false;
		this.termsOfUser = false;
		this.volumeList = [];
	}
}
