import { Injectable } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ModalConfirmComponent } from '../_shared/components/modal/modal-confirm/modal-confirm.component';
import { ModalGenericComponent } from '../_shared/components/modal/modal-generic/modal-generic.component';
import { ToastButton } from '@ionic/core/dist/types/components/toast/toast-interface';

@Injectable({ providedIn: 'root' })
export class MessageService {
	ionToast: HTMLIonToastElement;
	duration = 2000;

	constructor(private toastController: ToastController, private modalController: ModalController) {}

	async toast(message: string, color: string = '', duration = this.duration, buttons: ToastButton[] | null): Promise<HTMLIonToastElement> {
		this.ionToast = await this.toastController.create({
			message,
			color,
			duration,
			position: 'bottom',
			translucent: true,
			buttons,
		});
		this.ionToast.present().then();
		return this.ionToast;
	}

	toastDanger(message: string, duration?: number, ...buttons: ToastButton[] | null): Promise<HTMLIonToastElement> {
		return this.toast(message, 'danger', duration, buttons);
	}

	toastSuccess(message: string, duration?: number, ...buttons: ToastButton[] | null): Promise<HTMLIonToastElement> {
		return this.toast(message, 'success', duration, buttons);
	}

	toastWarning(message: string, duration?: number, ...buttons: ToastButton[] | null): Promise<HTMLIonToastElement> {
		return this.toast(message, 'warning', duration, buttons);
	}

	async modalConfirm(title: string, text: string): Promise<boolean> {
		const modal = await this.modalController.create({
			component: ModalConfirmComponent,
			cssClass: 'modal-confirm',
			componentProps: { title, text },
		});
		await modal.present();
		const data = await modal.onWillDismiss();
		return data.data as boolean;
	}

	async modalGeneric(title: string, text: string): Promise<boolean> {
		const modal = await this.modalController.create({
			component: ModalGenericComponent,
			// cssClass: 'modal-generic',
			componentProps: { title, text },
		});
		await modal.present();
		const data = await modal.onWillDismiss();
		return data.data;
	}
}
