import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, ParamMap, Router, RouterEvent } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PageService {
	previousUrl: string;
	currentUrl: string;

	constructor(private router: Router) {
		this.navigationEnd.subscribe(event => {
			this.previousUrl = this.currentUrl;
			this.currentUrl = event.url;
		});
	}

	get navigationEnd(): Observable<RouterEvent> {
		return this.router.events.pipe(filter(event => event instanceof NavigationEnd)) as Observable<RouterEvent>;
	}

	get getFirstUrl(): Observable<string> {
		return this.router.events.pipe(
			filter((it: any) => it?.url),
			take(1),
			map(it => it.url)
		);
	}

	navigate(url: string) {
		this.router.navigate([url]);
	}

	goToLoggedArea() {
		this.navigate('/cliente-area/contratos');
	}

	goToHome() {
		this.navigate('/home');
	}

	gotToLogin() {
		this.navigate('/cliente-area/login');
	}

	//this.route.snapshot.queryParamMap
	gotoCurrent(paramMap: ParamMap) {
		const queryParams: any =
			paramMap.keys
				.filter(it => !it.includes('TIME_'))
				.reduce(
					(a, v) => ({
						...a,
						[v]: paramMap.get(v),
					}),
					{}
				) || [];

		const dateTime = new Date().getTime();
		queryParams['TIME_' + dateTime] = dateTime;

		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			const navigationExtras: NavigationExtras = { queryParams };
			this.router.navigate(['coleta'], navigationExtras);
		});
	}
}
