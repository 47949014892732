import { ItemCotacaoDto } from './item-cotacao.dto';

export class CotacaoDto {
	cep: string;
	codCotacao: string;
	codProduto: string;
	codRota: string;
	codRotaFornecedor: string;
	codTipoRetirada: string;
	endereco: string;
	prazo: string;
	prazoTexto: string;
	produto: string;
	valor: number;
	valorTexto: string;
	itemCotacao: ItemCotacaoDto[];
	descricao: string;
}
