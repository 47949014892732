export enum StorageKeyEnum {
	USER = '#user',
	ORIGIN_LIST = '#originList',
	DESTINATION_LIST = '#destinationList',
	COTACAO = '#cotacao',
	CARD = '#card',
	FORM_VALID = '#formValid',
	VOLUME_LIST = '#volumeList',
	COUPON = '#coupon',
}
