export class UserDto {
	codUsuario: string;
	nome: string;
	login: string;
	token: string;
	pushIDAndroid: string;
	pushIDIOS: string;
	receberNotificacao: string;
	dataNascimento: string;
	codCliente: string;
	codFuncionario: string;
	isCompanyPartner: boolean;
	isActiveAccount: boolean;

	constructor(
		codUsuario = '',
		nome = '',
		login = '',
		token = '',
		pushIDAndroid = '',
		pushIDIOS = '',
		receberNotificacao = '',
		dataNascimento = '',
		codCliente = '',
		codFuncionario = '',
		codConta?: number,
		activate?: number
	) {
		this.codUsuario = codUsuario;
		this.nome = nome;
		this.login = login;
		this.token = token;
		this.pushIDAndroid = pushIDAndroid;
		this.pushIDIOS = pushIDIOS;
		this.receberNotificacao = receberNotificacao;
		this.dataNascimento = dataNascimento;
		this.codCliente = codCliente;
		this.codFuncionario = codFuncionario;
		this.isCompanyPartner = !!codConta && codConta > 1;
		this.isActiveAccount = !!activate && activate === 1;
	}

	get isCompanyPartnerAndActive(): boolean {
		return this.isCompanyPartner && this.isActiveAccount;
	}
}
