import { Injectable } from '@angular/core';
import { RedirectService } from '../core/services/redirect.service';
import { SessionRedirectService } from '../core/services/redirect/session-redirect.service';
import { NoRedirectService } from '../core/services/redirect/no-redirect.service';
import { LastRedirectService } from '../core/services/redirect/last-redirect.service';

@Injectable({ providedIn: 'root' })
export class InitializeService {
	constructor(
		private redirectService: RedirectService,
		private sessionRedirect: SessionRedirectService,
		private noRedirect: NoRedirectService,
		private lastRedirect: LastRedirectService
	) {}

	checkRedirect() {
		this.redirectService.checkRedirect([this.noRedirect.redirect, this.sessionRedirect.redirect, this.lastRedirect.redirect]);
	}

	initSession() {
		this.checkRedirect();
	}
}
