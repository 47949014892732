import { StringUtil } from '../_shared/util/string.util';
import { GenericDateModel } from './generic/GenericDate.model';
import { DateUtil } from '../_shared/util/date.util';

export class CreditCardModel implements GenericDateModel {
	nameOwner: string;
	cardNumber: string;
	expiration: string;
	cvv: string;
	brand: string;
	iuguId: string;

	constructor(nameOwner = '', cardNumber = '', expiration = '', cvv = null, brand = '', iuguId = '') {
		this.expiration = expiration;
		this.nameOwner = nameOwner;
		this.cardNumber = StringUtil.onlyNumber(cardNumber);
		this.cvv = cvv;
		this.brand = brand;
		this.iuguId = iuguId;
	}

	get firstName() {
		return this.nameOwner.split(' ').shift();
	}

	get lastName() {
		return this.nameOwner.split(' ').pop();
	}

	get expirationMonth(): number {
		return DateUtil.monthMM(this.expiration);
	}

	get expirationYear(): number {
		return DateUtil.yearYYYY(this.expiration);
	}
}
