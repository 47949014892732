import { BehaviorSubject } from 'rxjs';
import { AddressModel } from '../../models/address.model';
import { AddressTypeEnum } from '../../models/address-type.enum';

export class AddressListModel {
	list$: BehaviorSubject<AddressModel[]>;
	type: AddressTypeEnum;

	constructor(list$: BehaviorSubject<AddressModel[]>, type: AddressTypeEnum) {
		this.list$ = list$;
		this.type = type;
	}

	get size(): number {
		return this.list$.value.length;
	}

	hasMultipleDestination(): boolean {
		return this.size >= 2;
	}

	hasMoreAddressesThanVolumes(volList: any[]): boolean {
		return this.size > volList.length;
	}

	getTypeName(): string {
		return this.type === AddressTypeEnum.ORIGIN ? 'coleta (distribuição)' : 'entrega';
	}

	getTypeNameSimple(): string {
		return this.type === AddressTypeEnum.ORIGIN ? 'coleta' : 'entrega';
	}

	getTypeNameRevert(): string {
		return this.type === AddressTypeEnum.DESTINATION ? 'coleta (distribuição)' : 'entrega';
	}
}
