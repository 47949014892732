import { Injectable } from '@angular/core';
import { IRedirect } from './redirect.interface';
import { Router } from '@angular/router';
import { ArrayUtil } from '../../../_shared/util/array.util';
import { PageService } from '../page.service';

@Injectable({ providedIn: 'root' })
export class NoRedirectService implements IRedirect {
	private urlWithoutRedirection = ['/rastreador', '/home', '/contato', '/faq', '/noticias', '/coleta'];

	constructor(private router: Router, private pageService: PageService) {}

	redirect = async () => {
		const url = this.pageService.currentUrl || (await this.pageService.getFirstUrl.toPromise());
		return Promise.resolve(ArrayUtil.containsStringInArray(this.urlWithoutRedirection, url));
	};
}
