import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
	private ionLoading: HTMLIonLoadingElement;
	private show = false;

	constructor(public loadingController: LoadingController) {}

	async loading() {
		if (this.show) {
			return;
		}
		this.ionLoading = await this.loadingController.create({
			cssClass: 'my-custom-class',
			message: 'Carregando...',
			duration: 30000,
		});
		await this.ionLoading.present();
	}

	start() {
		this.loading().then();
		this.show = true;
	}

	stop() {
		timer(500).subscribe(() => {
			this.ionLoading?.dismiss().then();
			this.show = false;
		});
	}
}
