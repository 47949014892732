import { AddressDetailModel } from './address-detail.model';

export class VolumeModel {
	codVolume: string;
	codCotacao: string;
	codEstadoVolume: string;

	isPJ: boolean;

	peso: string;
	pesoAjuste: string;
	altura: string;
	comprimento: string;
	largura: string;
	valorNF: string;
	observacao: string;

	addressOriginIndex: number;
	detailOrigin: AddressDetailModel;

	addressDestinationIndex: number;
	detailDestination: AddressDetailModel;

	// itemCotacao: ItemCotacaoDto;

	constructor(
		codVolume?: string,
		codCotacao?: string,
		codEstadoVolume?: string,
		isPJ?: boolean,
		peso?: string,
		pesoAjuste?: string,
		altura?: string,
		comprimento?: string,
		largura?: string,
		valorNF?: string,
		observacao?: string,
		addressOriginIndex?: number,
		detailOrigin?: AddressDetailModel,
		addressDestinationIndex?: number,
		detailDestination?: AddressDetailModel
	) {
		this.codVolume = codVolume;
		this.codCotacao = codCotacao;
		this.codEstadoVolume = codEstadoVolume;
		this.isPJ = isPJ;
		this.peso = peso;
		this.pesoAjuste = pesoAjuste;
		this.altura = altura;
		this.comprimento = comprimento;
		this.largura = largura;
		this.valorNF = valorNF;
		this.observacao = observacao;
		this.addressOriginIndex = addressOriginIndex;
		this.detailOrigin = detailOrigin;
		this.addressDestinationIndex = addressDestinationIndex;
		this.detailDestination = detailDestination;
	}
}
