import { AddressModel } from '../../models/address.model';
import { BehaviorSubject } from 'rxjs';
import { AddressListModel } from './Address-list.model';
import { AddressTypeEnum } from '../../models/address-type.enum';
import { VolumeModel } from '../../models/volume.model';

export class StoreAddressList extends AddressListModel {
	list$: BehaviorSubject<AddressModel[]>;
	type: AddressTypeEnum;

	constructor(list$: BehaviorSubject<AddressModel[]>, type: AddressTypeEnum) {
		super(list$, type);
		this.list$ = list$;
		this.type = type;
	}

	next(address: AddressModel): void {
		this.list$.next(this.pushUniqueAddress(this.list$.value, address));
	}

	edit(index: number, address: AddressModel): void {
		this.list$.value[index] = address;
		this.list$.next(this.list$.value);
	}

	get(index: number): AddressModel {
		return this.list$.value.find((it, i) => i === index);
	}

	getAddress(volume: VolumeModel): AddressModel {
		return this.list$.value[this.type === AddressTypeEnum.ORIGIN ? volume.addressOriginIndex : volume.addressDestinationIndex];
	}

	private pushUniqueAddress(addressList: AddressModel[], address: AddressModel): AddressModel[] {
		return addressList?.length
			? [...addressList.filter(item => String(item.cep) !== String(address.cep) || String(item.numero) !== String(address.numero)), address]
			: [address];
	}
}
